// ES6 Polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import beitragsrechner from './modules/beitragsrechner';
import menuButton from './modules/menuButton';
import accordions from './modules/accordion';
import RegistrierungsformularMain from './react/apps/RegistrierungsformularMain.jsx';

function initRegistrierungsformular() {
    const mitgliedsantragWrapper = document.getElementById('registrierungsformular');
    if (mitgliedsantragWrapper) {
        const {dsn} = mitgliedsantragWrapper.dataset;

        if (dsn !== '') {
            Sentry.init({
                dsn,
            });
        }

        ReactDOM.render(
            <>
                <RegistrierungsformularMain />
            </>,
            mitgliedsantragWrapper,
        );
    }
}

window.onload = () => {
    beitragsrechner.init();
    initRegistrierungsformular();
    menuButton.init();
    accordions.init();
};
