import React from 'react';
import Button from '../../components/Button.jsx';

function Success() {
  return (
    <div>
        <div className="headline headline--level3 headline--green">Mitgliedsantrag wurde heruntergeladen.</div>

        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
            <div className="registrierungsformular-text">
                <p className="registrierungsformular-text">In dem Zip-Dokument finden Sie folgende Unterlagen:</p>

                <ul className="registrierungsformular-text">
                    <li>Beitrittserklärung</li>
                    <li>Satzung</li>
                    <li>Beitragsordnung</li>
                </ul>

                <p>
                    Drucken Sie bitte das Dokument <b>beitrittserklaerung.pdf</b> aus. Unterschreiben es und schicken es uns per Post zu.
                    Sie können es natürlich auch gerne persönlich bei uns vorbeibringen.
                    Wir freuen uns Sie im Lohnsteuerhilfeverein-Dormagen begrüßen zu dürfen.
                </p>
            </div>

            <Button href="/">Fertig</Button>
        </div>
    </div>
  );
}

export default Success;
