import React from 'react';

function Select(props) {
    return (
        <>
            <div className="multi-select">
                { props.label ? <label className="label">{props.label}</label> : ''}

                {Object.keys(props.options).map((key) => {
                    return (
                        <div>
                            <input className="multi-select__input" type="radio" value={key} id={key} {...props.register(props.name)}/>
                            <label className="multi-select__option" htmlFor={key}>{props.options[key]}</label>
                        </div>
                    );
                })}
            </div>
            {props.errors[props.name] && <div className="multi-select__error error">{props.errors[props.name].message}</div>}
        </>
    );
}

export default Select;
