import React, {useContext, useState} from 'react';
import {useForm} from 'react-hook-form';
import Row from '../../../components/Row.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormController from '../../../components/Registrierungsformular/FormController.jsx';
import {AppContext} from '../../../context/AppContext.jsx';
import YesNo from '../../../components/YesNo.jsx';
import {useNavigate} from 'react-router-dom';

const schema = yup.object({
    weitereEinkuenfte: yup.string().typeError('Bitte geben Sie an, ob Sie weitere Einkünfte haben.').required(),
}).required();

function Bemessungsgrundlage() {
    const navigate = useNavigate();
    const {updateStepWeitereEinkuenfte, registrierungsformular} = useContext(AppContext);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            weitereEinkuenfte: registrierungsformular?.weitereEinkuenfte
        },
    });

    const onSubmit = (data) => {
        updateStepWeitereEinkuenfte(data.weitereEinkuenfte);

        if (data.weitereEinkuenfte === 'true') {
            navigate('/bemessungsgrundlage/weitere-einkuenfte-hoehe');
        } else {
            navigate('/bemessungsgrundlage/weitere-einkuenfte-aus-selbststaendigkeit');
        }
    }

    return (
        <>
            <h1 className="headline--level3 headline--green">Bemessungs&shy;grundlage</h1>

            <p className="registrierungsformular-text">
                Haben Sie weitere Einkünfte?
                (z.B. aus Kapitalvermögen oder Vermietung und Verpachtung)
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <YesNo errors={errors} label="" name="weitereEinkuenfte" register={register} />
                </Row>

                <FormController handlePrevStep={() => {
                    navigate('/bemessungsgrundlage');
                }} />
            </form>
        </>
    );
}

export default Bemessungsgrundlage;
