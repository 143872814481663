class Beitragsrechner {
    constructor() {
        this.input = document.querySelector('.js-beitragsrechner-input');
        this.output = document.querySelector('.js-beitragsrechner-output');
        this.bemessungsgrundlageValue = document.querySelector('.js-beitragsrechner-value');

        if (!this.input || !this.output || !this.bemessungsgrundlageValue) {
            return;
        }

        this.input.addEventListener('input', this.change);

        this.change();
    }

    change = () => {
        try {
            this.output.innerHTML = `${Beitragsrechner.calculateCosts(Number(this.input.value))} €`;
            if (this.input.value > 100000) {
                this.bemessungsgrundlageValue.innerHTML = '> 100.000 €';
            } else {
                this.bemessungsgrundlageValue.innerHTML = `${Number(this.input.value).toLocaleString('de')} €`;
            }
        } catch (e) {
            console.error(e); // eslint-disable-line no-console
        }
    };

    static calculateCosts(bemessungsgrundlage) {
        switch (true) {
            case (bemessungsgrundlage <= 12500):
                return 55;
            case (bemessungsgrundlage <= 18000):
                return 75;
            case (bemessungsgrundlage <= 22000):
                return 85;
            case (bemessungsgrundlage <= 28000):
                return 95;
            case (bemessungsgrundlage <= 36000):
                return 130;
            case (bemessungsgrundlage <= 45000):
                return 150;
            case (bemessungsgrundlage <= 52000):
                return 170;
            case (bemessungsgrundlage <= 70000):
                return 190;
            case (bemessungsgrundlage <= 100000):
                return 240;
            case (bemessungsgrundlage >= 100000):
                return 290;
            default:
                throw new Error('Invalid Bemmessungsgrundlage');
        }
    }
}

// Dieses Objekt exponiert die API des Moduls nach Außen
// Die "init()" Funktion wie auch das instanziierte Objekt "exampleObject" können genutzt werden
// Kann auch die vollständige Funktionalität direkt beinhalten
const beitragsrechner = {
    init() {
        // Das Objekt sollte nur auf this gebunden werden, wenn es exponiert werden soll
        this.beitragsrechner = new Beitragsrechner();
    },
};

// Export der API
export default beitragsrechner;
