import React, {useContext} from 'react';
import {useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {AppContext} from '../../../context/AppContext.jsx';
import MultiSelect from '../../../components/MultiSelect.jsx';
import FormController from '../../../components/Registrierungsformular/FormController.jsx';
import {useNavigate} from 'react-router-dom';

const schema = yup.object({
    bemessungsgrundlage: yup.string().typeError('Bitte geben Sie eine Bemessungsgrundlage an.').required(),
}).required();

function Bemessungsgrundlage(props) {
    const navigate = useNavigate();
    const {updateStepBemessungsgrundlage, registrierungsformular} = useContext(AppContext);

    const { watch, register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            bemessungsgrundlage: registrierungsformular?.bemessungsgrundlage
        },
    });

    const bemessungsgrundlage = watch('bemessungsgrundlage');

    const onSubmit = (data) => {
        updateStepBemessungsgrundlage(data.bemessungsgrundlage);
        navigate('/bemessungsgrundlage/weitere-einkuenfte');
    };

    const calculateCosts = (bemessungsgrundlage) => {
        if (bemessungsgrundlage) {
            const bemessungsgrundlageNumber = Number(bemessungsgrundlage.substring(1));

            switch (true) {
                case (bemessungsgrundlageNumber <= 12500):
                    return 55;
                case (bemessungsgrundlageNumber <= 18000):
                    return 75;
                case (bemessungsgrundlageNumber <= 22000):
                    return 85;
                case (bemessungsgrundlageNumber <= 28000):
                    return 95;
                case (bemessungsgrundlageNumber <= 36000):
                    return 130;
                case (bemessungsgrundlageNumber <= 45000):
                    return 150;
                case (bemessungsgrundlageNumber <= 52000):
                    return 170;
                case (bemessungsgrundlageNumber <= 70000):
                    return 190;
                case (bemessungsgrundlageNumber <= 100000):
                    return 240;
                case (bemessungsgrundlageNumber >= 100000):
                    return 290;
                default:
                    return '-';
            }
        }
    }

    return (
        <>
            <h1 className="headline--level3 headline--green">Bemessungs&shy;grundlage</h1>
            <p className="registrierungsformular-text">
                Die Bemessungsgrundlage für den Beitragstarif bilden sämtliche steuerpflichtigen und steuerfreien Einnahmen des Mitglieds im Veranlagungszeitraum, der dem Beitragsjahr vorangeht.
                Bei Ehegatten und eingetragenen Lebenspartnerschaften wird die Bemessungsgrundlage aus den gemeinsamen Einnahmen gebildet.
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <MultiSelect errors={errors} label="" name="bemessungsgrundlage" register={register} options={{
                    '<12500': 'bis 12.500',
                    '<18000': 'bis 18.000',
                    '<22000': 'bis 22.000',
                    '<28000': 'bis 28.000',
                    '<36000': 'bis 36.000',
                    '<45000': 'bis 45.000',
                    '<52000': 'bis 52.000',
                    '<70000': 'bis 70.000',
                    '<100000': 'bis 100.000',
                    '>100000': 'ab 100.000',
                }} />

                <div className="info-box">
                    <div className="info-box__headline">Beitrag</div>

                    <div className="info-box__table">
                        <div className="info-box__text">Einmalige Aufnahmegebühr:</div><b>10 €</b>
                        <div className="info-box__text">Jahresbeitrag:</div><b id="bemessungsgrundlage-betrag">{bemessungsgrundlage ? calculateCosts(bemessungsgrundlage) : '-'} €</b>
                    </div>

                    <a target="_blank" className="info-box__link" href="/documents/beitragsordnung.pdf">Beitragsordnung</a>
                </div>

                <FormController handlePrevStep={() => {
                    navigate('/allgemein');
                }} />
            </form>
        </>
    );
}

export default Bemessungsgrundlage;
