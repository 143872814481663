import React from 'react';

function Row(props) {
    const classNames = ['row'];
    const numberOfChildren = props.children.length ?? 1;

    classNames.push(`row--row${numberOfChildren}`)

    if (props.firstSmall) {
        classNames.push('row--first-small');
    }

    return (
        <div className={classNames.join(' ')}>
            {props.children}
        </div>
    );
}

export default Row;
