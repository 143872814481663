import React from 'react';

export const defaultState = {
    registrierungsformular: {
        steuerpflichtiger: null,
        ehepartner: null,
        kinder: [],
        bemessungsgrundlage: null,
        weitereEinkuenfte: null,
        weitereEinkuenfteHoehe: null,
        weitereEinkuenfteAusSelbststaendigkeit: null,
    }
};

// export const defaultState = {
//     registrierungsformular: {
//         steuerpflichtiger: {
//             "religion": "römisch-katholisch",
//             "berufsbezeichnung": "asd",
//             "idNummer": "asd",
//             "gradDerBehinderung": 10,
//             "adresse": {
//                 "strasse": "Uferstraße",
//                 "hausnummer": "26",
//                 "plz": "41541",
//                 "ort": "Dormagen"
//             },
//             "vorname": "Mark",
//             "nachname": "Wolff",
//             "geburtsdatum": "1997-08-07T00:00:00-07:00",
//             "familienstand": "ledig",
//             "email": "mrwff@gmx.de",
//             "telefon": "01622860223"
//         },
//         ehepartner: null,
//         kinder: [],
//         bemessungsgrundlage: null,
//         weitereEinkuenfte: null,
//         weitereEinkuenfteHoehe: null,
//         weitereEinkuenfteAusSelbststaendigkeit: null,
//     }
// };

export const AppContext = React.createContext(defaultState);
