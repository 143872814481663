import React, {useContext, useEffect} from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {AppContext} from '../../../context/AppContext.jsx';
import {useNavigate} from 'react-router-dom';
import Button from '../../../components/Button.jsx';
import Input from '../../../components/Input.jsx';
import Row from '../../../components/Row.jsx';
import FormController from '../../../components/Registrierungsformular/FormController.jsx';

const schema = yup.object().shape({
    kinder: yup.array().of(
        yup.object().shape({
            vorname: yup.string()
                .required('Name is required'),
            nachname: yup.string()
                .required('Email is required')
        })
    )
});

function Zusammensetzen() {
    const navigate = useNavigate();
    const {updateStepFamilienmitglieder, registrierungsformular} = useContext(AppContext);
    const { register, handleSubmit, formState: { errors, }, control } = useForm({
        resolver: yupResolver(schema),
    });

    const {
        fields: kinderFields,
        append: appendKinderField,
        remove: removeKinderField
    } = useFieldArray({ name: 'kinder', control });
    const {
        fields: ehepartnerFields,
        append: appendEhepartnerField,
        remove: removeEhepartnerField
    } = useFieldArray({ name: 'ehepartner', control });

    useEffect(() => {
        registrierungsformular.kinder.forEach((kind) => {
            appendKinderField({
                vorname: kind.vorname,
                nachname: kind.nachname
            });
        });

        if (registrierungsformular.ehepartner) {
            appendEhepartnerField({
                vorname: registrierungsformular.ehepartner.vorname,
                nachname: registrierungsformular.ehepartner.nachname,
            });
        }
    }, []);

    const onSubmit = (data) => {
        updateStepFamilienmitglieder(data.ehepartner[0], data.kinder);

        if (data.ehepartner[0]) {
            navigate('/familienmitglieder/ehepartner');
        } else if (data.kinder.length > 0) {
            navigate('/familienmitglieder/kind/1');
        } else {
            navigate('/zusammenfassung');
        }
    };

    return (
        <>
            <h1 className="headline--level3 headline--green">Familienmitglieder</h1>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{marginBottom: '50px'}}>
                    <div className="headline headline--level4">Ehepartner</div>
                    {ehepartnerFields.map((field, index) => {
                        return (
                            <Row firstSmall key={index}>
                                <div style={{display: 'flex', alignItems: 'flex-end', cursor: 'pointer'}} onClick={() => {removeEhepartnerField(index)}}><img style={{width: '30px', marginBottom: '15px'}} src="/images/lhv/icons/bin.svg" alt=""/></div>
                                <Input errors={errors} label="Vorname" name={`ehepartner.${index}.vorname`} register={register} />
                                <Input errors={errors} label="Nachname" name={`ehepartner.${index}.nachname`} register={register} />
                            </Row>
                        );
                    })}
                    { ehepartnerFields < 1 ? <Button id="familienmitglieder-add-ehepartner" onClick={() => appendEhepartnerField({})}>+</Button> : ''}
                </div>

                <div style={{marginBottom: '50px'}}>
                    <div className="headline headline--level4">Kinder</div>
                    {kinderFields.map((field, index) => {
                        return (
                            <Row firstSmall key={index}>
                                <div style={{display: 'flex', alignItems: 'flex-end', cursor: 'pointer'}} onClick={() => {removeKinderField(index)}}><img style={{width: '30px', marginBottom: '15px'}} src="/images/lhv/icons/bin.svg" alt=""/></div>
                                <Input errors={errors} label="Vorname" name={`kinder.${index}.vorname`} register={register} />
                                <Input errors={errors} label="Nachname" name={`kinder.${index}.nachname`} register={register} />
                            </Row>
                        );
                    })}
                    <Button id="familienmitglieder-add-child" onClick={() => appendKinderField({})}>+</Button>
                </div>

                <FormController handlePrevStep={() => {
                    navigate('/allgemein');
                }} />
            </form>
        </>
    );
}

export default Zusammensetzen;
