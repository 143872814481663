class MenuButton {
    constructor() {
        this.menuButton = document.getElementById('js-menu-button');
        this.menuWrapper = document.getElementById('js-menu-wrapper');

        this.menuButton.addEventListener('click', this.toggle);
    }

    toggle = () => {
        this.menuButton.classList.toggle('is-active');

        if (!this.menuWrapper.classList.contains('active')) {
            this.menuWrapper.classList.add('fade-in');
            setTimeout(() => {
                this.menuWrapper.classList.add('active');
                this.menuWrapper.classList.remove('fade-in');
            }, 500);
        } else {
            this.menuWrapper.classList.add('fade-out');
            this.menuWrapper.classList.remove('active');
            setTimeout(() => {
                this.menuWrapper.classList.remove('fade-out');
            }, 500);
        }
    };
}

// Dieses Objekt exponiert die API des Moduls nach Außen
// Die "init()" Funktion wie auch das instanziierte Objekt "exampleObject" können genutzt werden
// Kann auch die vollständige Funktionalität direkt beinhalten
const menuButton = {
    init() {
        // Das Objekt sollte nur auf this gebunden werden, wenn es exponiert werden soll
        this.menuButton = new MenuButton();
    },
};

// Export der API
export default menuButton;
