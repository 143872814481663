import React from 'react';

function Input(props) {
    let errorElement = null;
    let errors = null;

    const errorIndexArray = props.name.split('.');
    if (errorIndexArray.length > 0) {
        let ref = props.errors;
        errorIndexArray.forEach((errorIndex, index) => {
            if (ref) {
                ref = ref[errorIndex];
            }
        });
        errors = ref;
    } else {
        errors = props.errors[props.name];
    }

    if (errors) {
        errorElement = <div className="error">{errors.message ?? ''}</div>;
    }

    return (
        <div>
            <label className="label">{props.label}</label>
            <input readOnly={props.readOnly ?? false} className="input" {...props.register(props.name)} type={props.type ?? 'text'} />
            {errorElement}
        </div>
    );
}

export default Input;
