import React, {useState, useEffect, useContext} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Sidebar from "../components/Sidebar.jsx";
import Overview from '../views/Registrierungsformular/Overview.jsx';
import Download from '../views/Registrierungsformular/Download.jsx';
import BeratungNichtMoeglich from '../views/Registrierungsformular/BeratungNichtMoeglich.jsx';
import Allgemein from '../modules/Steps/Allgemein.jsx';
import WeitereEinkuenfteForm from '../modules/Steps/Bemessungsgrundlage/WeitereEinkuenfteForm.jsx';
import WeitereEinkuenfteHoeheForm from '../modules/Steps/Bemessungsgrundlage/WeitereEinkuenfteHoeheForm.jsx';
import WeitereEinkuenfteSelbstaendigkeitForm from '../modules/Steps/Bemessungsgrundlage/WeitereEinkuenfteSelbstaendigkeitForm.jsx';
import Bemessungsgrundlage from '../modules/Steps/Bemessungsgrundlage/BemessungsgrundlageForm.jsx';
import Zusammensetzen from '../modules/Steps/Familienmitglieder/Zusammensetzen.jsx';
import Zusammenfassung from '../modules/Steps/Zusammenfassung.jsx';
import Ehepartner from '../modules/Steps/Familienmitglieder/Ehepartner.jsx';
import Kind from '../modules/Steps/Familienmitglieder/Kind.jsx';
import {AppContext} from '../context/AppContext.jsx';
import Success from '../views/Registrierungsformular/Success.jsx';
import Fehler from '../views/Registrierungsformular/Fehler.jsx';
import * as Sentry from "@sentry/react";

function Registrierungsformular() {
    const location = useLocation();
    const {registrierungsformular} = useContext(AppContext);
    const navigate = useNavigate();
    const [links, setLinks] = useState([
        {
            stepId: 1,
            name: 'Allgemein',
            disabled: true,
            to: '/allgemein',
            links: [
                '/allgemein',
            ],
        },
        {
            stepId: 2,
            name: 'Bemessungsgrundlage',
            disabled: true,
            to: '/bemessungsgrundlage',
            links: [
                '/bemessungsgrundlage',
                '/bemessungsgrundlage/weitere-einkuenfte',
                '/bemessungsgrundlage/weitere-einkuenfte-hoehe',
                '/bemessungsgrundlage/weitere-einkuenfte-aus-selbststaendigkeit',
            ]
        },
        {
            stepId: 3,
            name: 'Familienmitglieder',
            disabled: true,
            to: 'familienmitglieder',
            links: [
                '/familienmitglieder',
                '/familienmitglieder/ehepartner',
                '/familienmitglieder/kind',
            ]
        },
        {
            stepId: 4,
            name: 'Zusammenfassung',
            disabled: true,
            to: 'zusammenfassung',
            links: [
                '/zusammenfassung',
            ],
        },
    ]);

    useEffect(() => {
        if (location.pathname === '/download' || location.pathname === '/' || location.pathname === '/erledigt') {
            return;
        }

        if (!registrierungsformular.steuerpflichtiger && location.pathname !== '/allgemein') {
            navigate('/allgemein');
        }

        const newLinks = [...links];
        let activeReached = false;
        newLinks.forEach((link) => {
            let active = false;
            link.links.forEach((stepLink, index) => {
                if (location.pathname.startsWith(stepLink)) {
                    active = true;
                    activeReached = true;
                }
            });

            link.disabled = activeReached;
            link.active = false;

            if (active) {
                link.active = true;
                link.disabled = false;
            }
        })

        setLinks(newLinks);
    }, [location]);

    return (
        <div className="registrierungsformular">
            <Sentry.ErrorBoundary fallback={<Fehler />}>
                <Sidebar links={links} />

                <div className="registrierungsformular-content">
                    <Routes>
                        <Route path="/allgemein" element={<Allgemein />} />

                        <Route path="/bemessungsgrundlage" element={<Bemessungsgrundlage />} />
                        <Route path="/bemessungsgrundlage/weitere-einkuenfte" element={<WeitereEinkuenfteForm />} />
                        <Route path="/bemessungsgrundlage/weitere-einkuenfte-hoehe" element={<WeitereEinkuenfteHoeheForm />} />
                        <Route path="/bemessungsgrundlage/weitere-einkuenfte-aus-selbststaendigkeit" element={<WeitereEinkuenfteSelbstaendigkeitForm />} />

                        <Route path="/familienmitglieder" element={<Zusammensetzen />} />
                        <Route path="/familienmitglieder/ehepartner" element={<Ehepartner />} />
                        <Route path="/familienmitglieder/kind/:id" element={<Kind />} />

                        <Route path="/zusammenfassung" element={<Zusammenfassung />} />

                        <Route path="/beratung-nicht-moeglich" element={<BeratungNichtMoeglich />} />

                        <Route path="/download" element={<Download />} />
                        <Route path="/erledigt" element={<Success />} />
                        <Route path="/fehler" element={<Fehler />} />
                        <Route path="/" exact={true} element={<Overview />} />
                    </Routes>
                </div>
            </Sentry.ErrorBoundary>
        </div>
    );
}

export default Registrierungsformular;
