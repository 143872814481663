import React from 'react';
import {HashRouter} from 'react-router-dom';
import {AppProvider} from '../context/AppProvider.jsx';
import Registrierungsformular from './Registrierungsformular.jsx';

function RegistrierungsformularMain() {
    return (
        <HashRouter>
            <AppProvider>
                <Registrierungsformular />
            </AppProvider>
        </HashRouter>
    );
}

export default RegistrierungsformularMain;
