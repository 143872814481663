import React from 'react';
import {Link} from 'react-router-dom';

function Button(props) {
    const classNames = ['button'];

    if (props.ghost) {
        classNames.push('button--ghost-green')
    }

    if (props.href) {
        return (<a id={props.id ?? null} className={classNames.join(' ')} href={props.href} target={props.target ?? '_self'}>{props.children}</a>);
    }

    if (props.to) {
        return (<Link id={props.id ?? null} className={classNames.join(' ')} to={props.to}>{props.children}</Link>);
    }

    let loadingIcon;
    if (props.loading) {
        loadingIcon = (
            <img className="button__icon button__icon--loading" src="/images/lhv/icons/spinner.svg" alt="" />
        );
    }

    return (
        <button id={props.id ?? null} onClick={props.onClick} type={props.type ?? 'button'} className={classNames.join(' ')}>{props.children} {loadingIcon}</button>
    );
}

export default Button;