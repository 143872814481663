import React, {useState} from 'react';
import Button from '../../components/Button.jsx';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import FileDownload from 'js-file-download';
import YesNo from '../../components/YesNo.jsx';
import Row from '../../components/Row.jsx';
import Input from '../../components/Input.jsx';
import FormController from '../../components/Registrierungsformular/FormController.jsx';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = yup.object({
    anzahlDerKinder: yup.number().typeError('Bitte geben Sie an, wie viele Kinder Sie haben.').required(),
    verheiratet: yup.boolean().typeError('Bitte geben Sie an, ob Sie verheiratet sind.').required(),
}).required();

function Download() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = data => {
        setLoading(true);
        axios.post('/actions/mitgliedsantrag/default/manuell', {
            verheiratet: data.verheiratet,
            anzahlDerKinder: Number(data.anzahlDerKinder),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/zip'
            },
            responseType: "arraybuffer"
        })
        .then((response) => {
            if (typeof plausible !== 'undefined') {
                plausible('offline-antrag-heruntergeladen');
            }
            setLoading(false);
            FileDownload(response.data, 'beitrittserklaerung_lohnsteuerhilfeverein.zip');
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            navigate('/fehler');
        });
    };

    return (
        <div>
            <div className="headline headline--level2 headline--green">Beitrittserklärung</div>

            <p className="registrierungsformular-text">
                Laden Sie hier die Beitrittserklärung runter und füllen dies aus. Im Download finden Sie
                alle benötigten Dokumente. Nachdem Sie die Beitrittserklärung ausgefüllt haben schicken
                Sie uns diese bitte im Original zu.
                <br />
                <br />
                Damit wir Ihnen das richtige Dokument zur verfügung stellen können benötigen wir noch folgende angaben von Ihnen:
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <YesNo errors={errors} label="Sind Sie Verheiratet?" name="verheiratet" register={register} />
                </Row>

                <Row>
                    <Input type="number" errors={errors} label="Wie viele Kinder haben Sie?" name="anzahlDerKinder" register={register} />
                </Row>

                <FormController loading={loading} noAbort nextText="herunterladen" handlePrevStep={() => {
                    navigate('/');
                }} />
            </form>
        </div>
    );
}

export default Download;
