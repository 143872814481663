import React from 'react';

function Select(props) {
    return (
        <>
            <div className="multi-select multi-select--yesno">
                { props.label ? <label className="label">{props.label}</label> : ''}

                <div className="multi-select__option-wrapper">
                    <div className="multi-select__yesno-wrapper">
                        <input className="multi-select__input" type="radio" value={'true'} id={`${props.name}_yes`} {...props.register(props.name)}/>
                        <label className="multi-select__option" htmlFor={`${props.name}_yes`}>Ja</label>
                    </div>

                    <div className="multi-select__yesno-wrapper">
                        <input className="multi-select__input" type="radio" value={'false'} id={`${props.name}_no`} {...props.register(props.name)}/>
                        <label className="multi-select__option" htmlFor={`${props.name}_no`}>Nein</label>
                    </div>
                </div>
                {props.errors[props.name] && <div className="error">{props.errors[props.name].message}</div>}
            </div>

        </>
    );
}

export default Select;
