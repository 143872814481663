import React from 'react';
import Button from '../Button.jsx';

function FormController(props) {
    return (
        <div className="registrierungsformular-form-control">
            {props.noAbort ? '' : <Button href="/mitglied-werden" ghost>Abbrechen</Button>}

            <div className="registrierungsformular-form-control__buttons-right">
                {props.handlePrevStep ? <Button onClick={props.handlePrevStep} ghost id="formControl-back">zurück</Button> : ''}
                <Button type="submit" loading={props.loading ?? false}>{props.nextText ?? 'Weiter'}</Button>
            </div>
        </div>
    );
}

export default FormController;
