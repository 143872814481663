class Accordion {
    constructor() {
        this.accordions = document.querySelectorAll('.js-accordion');
        this.objects = [];

        this.accordions.forEach((element, index) => {
            element.addEventListener('click', () => this.toggle(index));
        });
    }

    toggle = (index) => {
        const item = this.accordions[index];
        const content = item.querySelector('.js-accordion-content');

        this.accordions.forEach((element) => {
            // eslint-disable-next-line
            element.querySelector('.js-accordion-content').style.maxHeight = '0px';
            element.classList.remove('active');
        });

        if (item.style.maxHeight) {
            content.style.maxHeight = null;
            item.classList.remove('active');
        } else {
            content.style.maxHeight = `${content.scrollHeight}px`;
            item.classList.add('active');
        }
    };
}

const accordions = {
    init() {
        return new Accordion();
    },
};

// Export der API
export default accordions;
