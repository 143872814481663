import React from 'react';

function Select(props) {
    return (
        <div >
            <label className="label">{props.label}</label>
            <select disabled={props.disabled} className="select" {...props.register(props.name)} defaultValue={props.default}>
                {Object.keys(props.options).map((key) => {
                    return (<option key={key} value={key}>{props.options[key]}</option>);
                })}
            </select>
        </div>
    );
}

export default Select;
