import React from 'react';
import Button from '../../components/Button.jsx';

function Overview() {
  return (
    <div>
        <div className="headline headline--level2 headline--green">Mitglied werden</div>
        <p className="registrierungsformular-text">
            Um Mitglied im Lohnsteuerhilfeverein-Dormagen zu werden kannst Du entweder die Beitrittserklärung
            herunterladen, oder Du füllst sie einfach im Browser aus.
            <br />
            <br />
            Wir erstellen deine Einkommensteuererklärung, wenn Du Einkünfte aus nicht selbstständiger Arbeit, Renten, Pensionen oder Unterhaltsleistungen erzielst.
            Das gilt auch, wenn Du bis zu einer begrenzten Höhe Einkünfte aus Vermietung und Verpachtung, sonstige Einkünften und Einkünfte aus Kapitalvermögen hast.
            <br />
            <br />
            Danach schickst Du die Beitrittserklärung zu uns und wir melden uns bei Dir mit weiteren Informationen.
        </p>

        <div className="registrierungsformular__button-wrapper">
            <Button ghost to="/download">Ausdrucken</Button>
            <Button to="/allgemein">Online ausfüllen</Button>
        </div>
    </div>
  );
}

export default Overview;
