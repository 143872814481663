import React, {useState} from 'react';
import {AppContext, defaultState} from './AppContext.jsx';

export const AppProvider = ({children}) => {
    const [registrierungsformular, setRegistrierungsformular] = useState(defaultState.registrierungsformular);

    const updateStepAllgemein = (steuerpflichtiger) => {
        const updatedState = {...registrierungsformular};

        updatedState.steuerpflichtiger = steuerpflichtiger;

        setRegistrierungsformular(updatedState);
    };

    const updateStepBemessungsgrundlage = (bemessungsgrundlage) => {
        const updatedState = {...registrierungsformular};

        updatedState.bemessungsgrundlage = bemessungsgrundlage;

        setRegistrierungsformular(updatedState);
    };

    const updateStepWeitereEinkuenfte = (weitereEinkuenfte) => {
        const updatedState = {...registrierungsformular};

        updatedState.weitereEinkuenfte = weitereEinkuenfte;

        setRegistrierungsformular(updatedState);
    };

    const updateStepWeitereEinkuenfteZuHoch = (weitereEinkuenfteHoehe) => {
        const updatedState = {...registrierungsformular};

        updatedState.weitereEinkuenfteHoehe = weitereEinkuenfteHoehe;

        setRegistrierungsformular(updatedState);
    };

    const updateStepWeitereEinkuenfteAusSelbststaendigkeit = (weitereEinkuenfteAusSelbststaendigkeit) => {
        const updatedState = {...registrierungsformular};

        updatedState.weitereEinkuenfteAusSelbststaendigkeit = weitereEinkuenfteAusSelbststaendigkeit;

        setRegistrierungsformular(updatedState);
    };

    const updateStepFamilienmitglieder = (ehepartner, kinder) => {
        const updatedState = {...registrierungsformular};

        if (!updatedState.ehepartner) {
            updatedState.ehepartner = {};
        }

        if (ehepartner) {
            updatedState.ehepartner.geschlecht = ehepartner.geschlecht;
            updatedState.ehepartner.vorname = ehepartner.vorname;
            updatedState.ehepartner.nachname = ehepartner.nachname;
        }

        kinder.forEach((kind, index) => {
            if (!updatedState.kinder[index]) {
                updatedState.kinder[index] = {};
            }

            updatedState.kinder[index].geschlecht = kind.geschlecht;
            updatedState.kinder[index].vorname = kind.vorname;
            updatedState.kinder[index].nachname = kind.nachname;
        });

        setRegistrierungsformular(updatedState);
    };

    const updateStepEhepartner = (ehepartner) => {
        const updatedState = {...registrierungsformular};

        updatedState.ehepartner = ehepartner;

        setRegistrierungsformular(updatedState);
    };

    const updateStepKind = (id, kind) => {
        const updatedState = {...registrierungsformular};

        updatedState.kinder[id] = kind;

        setRegistrierungsformular(updatedState);
    };

    return (
        <AppContext.Provider
            value={{
                registrierungsformular,
                updateStepAllgemein,
                updateStepBemessungsgrundlage,
                updateStepWeitereEinkuenfte,
                updateStepWeitereEinkuenfteZuHoch,
                updateStepWeitereEinkuenfteAusSelbststaendigkeit,
                updateStepFamilienmitglieder,
                updateStepEhepartner,
                updateStepKind
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
