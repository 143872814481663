import React from 'react';
import Button from '../../components/Button.jsx';

function BeratungNichtMoeglich() {
  return (
    <div>
        <div className="headline headline--level1 headline--green">Beratung nicht möglich.</div>
        <p className="registrierungsformular-text">Es tut uns leid, Ihre Angaben übersteigen den Beratungsrahmen des Lohnsteuerhilfevereins. Für eine individuelle Beratung nehmen Sie gerne Kontakt zu unserem Korrespondenzpartner KSW-Gruppe auf.</p>

        <div style={{display: 'flex'}}>
            <div className="registrierungsformular__button-wrapper">
                <Button ghost href="/">zurück</Button>
                <Button href="https://kswg.eu/dormagen/" target="_blank">zu KSWG</Button>
            </div>
        </div>
    </div>
  );
}

export default BeratungNichtMoeglich;
