import React, {useContext, useState} from 'react';
import {AppContext} from '../../context/AppContext.jsx';
import {useNavigate} from 'react-router-dom';
import Button from '../../components/Button.jsx';
import FileDownload from 'js-file-download';
import axios from 'axios';
import moment from 'moment';

function Zusammenfassung() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {registrierungsformular} = useContext(AppContext);

    const mapKirchensteuerpflicht = (value) => {
        const mapping = {
            'ak': 'altkatholisch',
            'ev': 'evangelisch',
            'fg': 'freireligiöse Gemeinde',
            'fr': 'französisch-reformiert',
            'isr': 'israelitisch',
            'jd': 'jüdisch',
            'lt': 'evangelisch-lutherisch',
            'rf': 'evangelisch-reformiert',
            'rk': 'römisch-katholisch',
            'un': 'unitarisch-protestantisch',
            'vd': 'keine Kirchensteuerpflicht'
        };

        return mapping[value];
    }

    const handleGeneratePdf = () => {
        setLoading(true);
        const daten = {
            "steuerpflichtiger": {
                "kirchsteuerpflicht": registrierungsformular.steuerpflichtiger.kirchsteuerpflicht,
                "kirchsteuerpflichtGewesen": registrierungsformular.steuerpflichtiger.kirchsteuerpflichtGewesen,
                "berufsbezeichnung": registrierungsformular.steuerpflichtiger.berufsbezeichnung,
                "idNummer": registrierungsformular.steuerpflichtiger.idNummer,
                "gradDerBehinderung": registrierungsformular.steuerpflichtiger.gradDerBehinderung === 'kein' ? null : Number(registrierungsformular.steuerpflichtiger.gradDerBehinderung),
                "adresse": {
                    "strasse": registrierungsformular.steuerpflichtiger.adresse.strasse,
                    "hausnummer": registrierungsformular.steuerpflichtiger.adresse.hausnummer,
                    "plz": registrierungsformular.steuerpflichtiger.adresse.plz,
                    "ort": registrierungsformular.steuerpflichtiger.adresse.ort,
                },
                "vorname": registrierungsformular.steuerpflichtiger.vorname,
                "nachname": registrierungsformular.steuerpflichtiger.nachname,
                "geburtsdatum": moment(registrierungsformular.steuerpflichtiger.geburtsdatum).format(),
                "familienstand": registrierungsformular.steuerpflichtiger.familienstand,
                "familienstandDatum": moment(registrierungsformular.steuerpflichtiger.familienstandDatum).format(),
                "email": registrierungsformular.steuerpflichtiger.email,
                "telefon": registrierungsformular.steuerpflichtiger.telefon,
            }
        };

        if (registrierungsformular.steuerpflichtiger.kirchsteuerpflicht !== 'vd') {
            daten.steuerpflichtiger.kircheneintritt = registrierungsformular.steuerpflichtiger.kircheneintritt;
        } else {
            if (registrierungsformular.steuerpflichtiger.kirchsteuerpflichtGewesen) {
                daten.steuerpflichtiger.kirchenaustritt = registrierungsformular.steuerpflichtiger.kirchenaustritt;
            }
        }

        if (registrierungsformular.ehepartner.vorname) {
            daten.ehepartner = {
                "vorname": registrierungsformular.ehepartner.vorname,
                "nachname": registrierungsformular.ehepartner.nachname,
                "kirchsteuerpflicht": registrierungsformular.steuerpflichtiger.kirchsteuerpflicht,
                "kirchsteuerpflichtGewesen": registrierungsformular.steuerpflichtiger.kirchsteuerpflichtGewesen,
                "geburtsdatum": moment(registrierungsformular.ehepartner.geburtsdatum).format(),
                "idNummer": registrierungsformular.ehepartner.idNummer,
                "berufsbezeichnung": registrierungsformular.ehepartner.berufsbezeichnung,
                "gradDerBehinderung": registrierungsformular.ehepartner.gradDerBehinderung === 'kein' ? null : Number(registrierungsformular.ehepartner.gradDerBehinderung),
                "telefon": registrierungsformular.ehepartner.telefon
            };

            if (registrierungsformular.ehepartner.andereAdresse && registrierungsformular.ehepartner.andereAdresse !== 'false') {
                daten.ehepartner.adresse = {
                    "strasse": registrierungsformular.ehepartner.adresse.strasse,
                    "hausnummer": registrierungsformular.ehepartner.adresse.hausnummer,
                    "plz": registrierungsformular.ehepartner.adresse.plz,
                    "ort": registrierungsformular.steuerpflichtiger.adresse.ort,
                };
            }

            if (registrierungsformular.ehepartner.kirchsteuerpflicht !== 'vd') {
                daten.ehepartner.kircheneintritt = registrierungsformular.ehepartner.kircheneintritt;
            } else {
                if (registrierungsformular.ehepartner.kirchsteuerpflichtGewesen) {
                    daten.ehepartner.kirchenaustritt = registrierungsformular.ehepartner.kirchenaustritt;
                }
            }
        }

        if (registrierungsformular.kinder.length > 0) {
            daten.kinder = [];
            registrierungsformular.kinder.forEach((kind, index) => {
                daten.kinder[index] = {
                    "vorname": kind.vorname,
                    "nachname": kind.nachname,
                    "geburtsdatum": moment(kind.geburtsdatum).format(),
                    "idNummer": kind.idNummer,
                    "volljaehrig": true,
                    "berufsbezeichnung": kind.berufsbezeichnung,
                    "kindergeldberechtigt": kind.kindergeldberechtigung === 'true',
                };

                if (kind.leiblichesElternteil) {
                    daten.kinder[index].leiblichesElternteil = {};
                    daten.kinder[index].leiblichesElternteil = {
                        "vorname": kind.leiblichesElternteil.vorname,
                        "nachname": kind.leiblichesElternteil.nachname,
                        "idNummer": kind.leiblichesElternteil.idNummer,
                        "gradDerBehinderung": kind.leiblichesElternteil.gradDerBehinderung === 'kein' ? null : Number(kind.leiblichesElternteil.gradDerBehinderung),
                        "geburtsdatum": moment(kind.leiblichesElternteil.geburtsdatum).format(),
                        "adresse": {
                            "strasse": kind.leiblichesElternteil.adresse.strasse,
                            "hausnummer": kind.leiblichesElternteil.adresse.hausnummer,
                            "plz": kind.leiblichesElternteil.adresse.plz,
                            "ort": kind.leiblichesElternteil.adresse.ort,
                        }
                    }
                }
            });
        }

        daten.finanzamt = registrierungsformular.steuerpflichtiger.allgemein.finanzamt;
        daten.steuerNummer = registrierungsformular.steuerpflichtiger.allgemein.steuernummer;
        daten.bemessungsgrundlage = registrierungsformular.bemessungsgrundlage;

        axios.post('/actions/mitgliedsantrag/default/online', daten,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/zip'
            },
            responseType: "arraybuffer"
        })
        .then((response) => {
            setLoading(false);
            if (typeof plausible !== 'undefined') {
                plausible('online-antrag-ausgefuellt');
            }
            FileDownload(response.data, 'beitrittserklaerung_lohnsteuerhilfeverein.zip');
            navigate('/erledigt');
        })
        .catch((error) => {
            setLoading(false);
            console.error(error);
            navigate('/fehler');
        });
    }

    return (
        <>
            <h1 className="headline--level3 headline--green">Zusammenfassung</h1>

            <div className="registrierungsformular-zusammenfassung__box">
                <div className="info-box__headline">Steuerpflichtiger</div>

                <div className="registrierungsformular-zusammenfassung__list">
                    <div><b>Vorname:</b> {registrierungsformular.steuerpflichtiger.vorname ?? '-'}</div>
                    <div><b>Nachname:</b> {registrierungsformular.steuerpflichtiger.nachname ?? '-'}</div>
                    <div><b>Geschlecht:</b> {registrierungsformular.steuerpflichtiger.geschlecht}</div>
                    <div><b>Geburtsdatum:</b> {moment(registrierungsformular.steuerpflichtiger.geburtsdatum).format('DD.MM.Y')}</div>
                    <div><b>Familienstand:</b> {registrierungsformular.steuerpflichtiger.familienstand}</div>
                    <div><b>{registrierungsformular.steuerpflichtiger.familienstand}  seit:</b> {moment(registrierungsformular.steuerpflichtiger.familienstandDatum).format('DD.MM.Y')}</div>

                    <div><b>Religion:</b> {mapKirchensteuerpflicht(registrierungsformular.steuerpflichtiger.kirchsteuerpflicht)}</div>
                    {registrierungsformular.steuerpflichtiger.kirchsteuerpflicht !== 'vd' ?
                        <div><b>Kircheneintritt:</b> {moment(registrierungsformular.steuerpflichtiger.kircheneintritt).format('DD.MM.Y')}</div>
                    :
                        <>
                            {registrierungsformular.steuerpflichtiger.kirchsteuerpflichtGewesen ?
                                <div><b>Kirchenaustritt:</b> {moment(registrierungsformular.steuerpflichtiger.kirchenaustritt).format('DD.MM.Y')}</div>
                                : ''}
                        </>
                    }

                    <div><b>Steuer-Id:</b> {registrierungsformular.steuerpflichtiger.idNummer}</div>

                    <div><b>Berufsstatus:</b> {registrierungsformular.steuerpflichtiger.berufstatus}</div>
                    <div><b>Berufsbezeichnung:</b> {registrierungsformular.steuerpflichtiger.berufsbezeichnung}</div>

                    <div><b>Grad der Behinderung:</b> {registrierungsformular.steuerpflichtiger.gradDerBehinderung === 'kein' ? '-' : Number(registrierungsformular.steuerpflichtiger.gradDerBehinderung)}</div>

                    <div><b>Adresse:</b> {registrierungsformular.steuerpflichtiger.adresse.strasse} {registrierungsformular.steuerpflichtiger.adresse.hausnummer}, {registrierungsformular.steuerpflichtiger.adresse.plz} {registrierungsformular.steuerpflichtiger.adresse.ort}</div>

                    <div><b>E-Mail:</b> {registrierungsformular.steuerpflichtiger.email}</div>
                    <div><b>Telefon:</b> {registrierungsformular.steuerpflichtiger.telefon}</div>

                    <div><b>Finanzamt:</b> {registrierungsformular.steuerpflichtiger.allgemein.finanzamt}</div>
                    <div><b>Steuernummer:</b> {registrierungsformular.steuerpflichtiger.allgemein.steuernummer}</div>
                </div>
            </div>

            {registrierungsformular.ehepartner.vorname ?
                <div className="registrierungsformular-zusammenfassung__box">
                    <div className="info-box__headline">Ehepartner</div>

                    <div className="registrierungsformular-zusammenfassung__list">
                        <div><b>Vorname:</b> {registrierungsformular.ehepartner.vorname ?? '-'}</div>
                        <div><b>Nachname:</b> {registrierungsformular.ehepartner.nachname ?? '-'}</div>
                        <div><b>Geschlecht:</b> {registrierungsformular.ehepartner.geschlecht}</div>
                        <div><b>Geburtsdatum:</b> {moment(registrierungsformular.ehepartner.geburtsdatum).format('DD.MM.Y')}</div>

                        <div><b>Religion:</b> {mapKirchensteuerpflicht(registrierungsformular.ehepartner.kirchsteuerpflicht)}</div>
                        {registrierungsformular.ehepartner.kirchsteuerpflicht !== 'vd' ?
                            <div><b>Kircheneintritt:</b> {moment(registrierungsformular.ehepartner.kircheneintritt).format('DD.MM.Y')}</div>
                            :
                            <>
                                {registrierungsformular.ehepartner.kirchsteuerpflichtGewesen ?
                                    <div><b>Kirchenaustritt:</b> {moment(registrierungsformular.ehepartner.kirchenaustritt).format('DD.MM.Y')}</div>
                                    : ''}
                            </>
                        }

                        <div><b>Steuer-Id:</b> {registrierungsformular.ehepartner.idNummer}</div>

                        <div><b>Berufsstatus:</b> {registrierungsformular.ehepartner.berufstatus}</div>
                        <div><b>Berufsbezeichnung:</b> {registrierungsformular.ehepartner.berufsbezeichnung}</div>

                        <div><b>Grad der Behinderung:</b> {registrierungsformular.ehepartner.gradDerBehinderung === 'kein' ? '-' : Number(registrierungsformular.ehepartner.gradDerBehinderung)}</div>

                        <div><b>Telefon:</b> {registrierungsformular.ehepartner.telefon}</div>

                        {registrierungsformular.ehepartner.andereAdresse === 'true' || registrierungsformular.ehepartner.andereAdresse === true ?
                            <div><b>Adresse:</b> {registrierungsformular.ehepartner.adresse.strasse} {registrierungsformular.ehepartner.adresse.hausnummer}, {registrierungsformular.ehepartner.adresse.plz} {registrierungsformular.ehepartner.adresse.ort}</div>
                        : ''}
                    </div>
                </div>
            :''}

            {registrierungsformular.kinder.map((kind, index) => {
                return <div className="registrierungsformular-zusammenfassung__box">
                    <div className="info-box__headline">Kind {index + 1}</div>

                    <div className="registrierungsformular-zusammenfassung__list">
                        <div><b>Vorname:</b> {kind.vorname ?? '-'}</div>
                        <div><b>Nachname:</b> {kind.nachname ?? '-'}</div>
                        <div><b>Geschlecht:</b> {kind.geschlecht}</div>
                        <div><b>Geburtsdatum:</b> {moment(kind.geburtsdatum).format('DD.MM.Y')}</div>

                        <div><b>Religion:</b> {mapKirchensteuerpflicht(kind.kirchsteuerpflicht)}</div>
                        {kind.kirchsteuerpflicht !== 'vd' ?
                            <div><b>Kircheneintritt:</b> {moment(kind.kircheneintritt).format('DD.MM.Y')}</div>
                        :
                            <>
                                {kind.kirchsteuerpflichtGewesen ?
                                    <div><b>Kirchenaustritt:</b> {moment(kind.kirchenaustritt).format('DD.MM.Y')}</div>
                                : ''}
                            </>
                        }

                        <div><b>Steuer-Id:</b> {kind.idNummer}</div>

                        <div><b>Berufsstatus:</b> {kind.berufstatus}</div>
                        <div><b>Berufsbezeichnung:</b> {kind.berufsbezeichnung}</div>

                        <div><b>Grad der Behinderung:</b> {kind.gradDerBehinderung === 'kein' ? '-' : Number(kind.gradDerBehinderung)}</div>

                        <div><b>Kindergeldberechtigt:</b> {kind.kindergeldberechtigung ? 'ja' : 'nein'}</div>

                        {kind.leiblichesElternteil ?
                            <>
                                <br />
                                <div><b>Leibliches Elternteil</b></div>
                                <div><b>Vorname:</b> {kind.leiblichesElternteil.vorname ?? '-'}</div>
                                <div><b>Nachname:</b> {kind.leiblichesElternteil.nachname ?? '-'}</div>
                                <div><b>Steuer-Id:</b> {kind.leiblichesElternteil.idNummer}</div>
                                <div><b>Grad der Behinderung:</b> {kind.leiblichesElternteil.gradDerBehinderung === 'kein' ? '-' : Number(kind.leiblichesElternteil.gradDerBehinderung)}</div>
                                <div><b>Adresse:</b> {kind.leiblichesElternteil.adresse.strasse} {kind.leiblichesElternteil.adresse.hausnummer}, {kind.leiblichesElternteil.adresse.plz} {kind.leiblichesElternteil.adresse.ort}</div>
                            </>
                        : ''}
                    </div>
                </div>
            })}

            <div className="registrierungsformular__button-wrapper registrierungsformular__button-wrapper--right">
                <Button ghost id="back" onClick={() => {
                    if (registrierungsformular.kinder?.length > 0) {
                        navigate(`/familienmitglieder/kind/${registrierungsformular.kinder.length}`);
                    } else {
                        if (registrierungsformular.ehepartner) {
                            navigate(`/familienmitglieder/kind/${registrierungsformular.kinder.length}`);
                        } else {
                            navigate('/familienmitglieder');
                        }
                    }
                }}>zurück</Button>
                <Button id="download" onClick={handleGeneratePdf} loading={loading}>Mitgliedsantrag generieren</Button>
            </div>
        </>
    );
}

export default Zusammenfassung;
