import React, {useContext} from 'react';
import {useForm} from 'react-hook-form';
import Row from '../../../components/Row.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormController from '../../../components/Registrierungsformular/FormController.jsx';
import {AppContext} from '../../../context/AppContext.jsx';
import YesNo from '../../../components/YesNo.jsx';
import {useNavigate} from 'react-router-dom';

const schema = yup.object({
    weitereEinkuenfteAusSelbststaendigkeit: yup.string().typeError('Bitte geben Sie an, ob Sie Einnahmen aus den gefragten Kategorien haben.').required(),
}).required();

function Bemessungsgrundlage() {
    const navigate = useNavigate();
    const {updateStepWeitereEinkuenfteAusSelbststaendigkeit, registrierungsformular} = useContext(AppContext);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            weitereEinkuenfteAusSelbststaendigkeit: registrierungsformular?.weitereEinkuenfteAusSelbststaendigkeit
        }
    });

    const onSubmit = (data) => {
        updateStepWeitereEinkuenfteAusSelbststaendigkeit(data.weitereEinkuenfteAusSelbststaendigkeit);

        if (data.weitereEinkuenfteAusSelbststaendigkeit === 'true') {
            navigate('/beratung-nicht-moeglich');
        } else {
            navigate('/familienmitglieder');
        }
    }

    return (
        <>
            <h1 className="headline--level3 headline--green">Bemessungs&shy;grundlage</h1>

            <div className="registrierungsformular-text">
                <p style={{marginBottom: '20px'}}>Haben Sie oder Ihr Ehepartner Einnahmen aus:</p>

                <ul>
                    <li>Land- und Forstwirtschaft</li>
                    <li>Gewerbebetrieb/ Kleingewerbe</li>
                    <li>selbstständiger Arbeit oder erwirtschaften umsatzsteuerpflichtigen Umsätze?</li>
                </ul>
            </div>


            <form onSubmit={handleSubmit(onSubmit)}>

                <Row>
                    <YesNo errors={errors} label="" name="weitereEinkuenfteAusSelbststaendigkeit" register={register} />
                </Row>

                <FormController handlePrevStep={() => {
                    navigate('/bemessungsgrundlage/weitere-einkuenfte-hoehe');
                }} />
            </form>
        </>
    );
}

export default Bemessungsgrundlage;
