import React, {useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {AppContext} from '../../../context/AppContext.jsx';
import {useNavigate, useParams} from 'react-router-dom';
import Input from '../../../components/Input.jsx';
import Row from '../../../components/Row.jsx';
import FormController from '../../../components/Registrierungsformular/FormController.jsx';
import Select from '../../../components/Select.jsx';
import YesNo from '../../../components/YesNo.jsx';

const schema = yup.object({
    vorname: yup.string().required('Bitte geben Sie den Vornamen an.'),
    nachname: yup.string().required('Bitte geben Sie den Nachnamen an.'),
    geschlecht: yup.string().required('Bitte geben Sie das Geschlecht an.'),
    gradDerBehinderung: yup.string().required('Bitte geben Sie den Grad der Behinderung an.'),
    berufstatus: yup.string().required('Bitte geben Sie den Berufsstatus an.'),
    berufsbezeichnung: yup.string('Bitte geben Sie den Berufsbezeichnung an.'),
    geburtsdatum: yup.date().required('Bitte geben Sie das Geburtsdatum an.'),
    idNummer: yup.string().required('Bitte geben Sie die Steuer-Id an.'),
    kirchsteuerpflicht: yup.string().required('Bitte geben Sie die Kirchsteuerpflicht an.'),
    kirchsteuerpflichtigGewesen: yup.string('Bitte geben Sie an, ob Sie Kirchensteuerpflichtig gewesen sind.').nullable().transform((curr, orig) => orig === '' ? null : curr).when("kirchsteuerpflicht", {
        is: (value) => value === 'vd',
        then: yup.string().required('Bitte geben Sie an, ob Sie Kirchensteuerpflichtig gewesen sind.').nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
    kircheneintritt: yup.date('Bitte geben Sie an, ob Sie das Datum des Kircheneintritts an.').nullable().transform((curr, orig) => orig === '' ? null : curr).when("kirchsteuerpflicht", {
        is: (value) => value !== 'vd',
        then: yup.date().required('Bitte geben Sie an, ob Sie das Datum des Kircheneintritts an.').nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
    kirchenaustritt: yup.date('Bitte geben Sie an, ob Sie das Datum des Kirchenaustritts an.').nullable().transform((curr, orig) => orig === '' ? null : curr).when("kirchsteuerpflichtigGewesen", {
        is: (value) => value === 'true' || value === true,
        then: yup.date().required('Bitte geben Sie an, ob Sie das Datum des Kirchenaustritts an.').nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
    kindergeldberechtigung: yup.string().required(),
    anderesLeiblichesElternteil: yup.string('Bitte geben Sie an, ob es ein anderes leibliches Elternteil gibt.').required(),
    leiblichesElternteil: yup.object({
        vorname: yup.string().required('Bitte geben Sie den Vornamen an.'),
        nachname: yup.string().required('Bitte geben Sie den Nachnamen an.'),
        idNummer: yup.string().required('Bitte geben Sie die Steuer-Id an.'),
        gradDerBehinderung: yup.string().required('Bitte geben Sie den Grad der Behinderung an.'),
        adresse: yup.object({
            strasse: yup.string().required('Bitte geben Sie ihre Straße an.'),
            hausnummer: yup.string().required('Bitte geben Sie ihre Hausnummer an.'),
            plz: yup.string().required('Bitte geben Sie ihre PLZ an.'),
            ort: yup.string().required('Bitte geben Sie ihren Ort an.'),
        }),
    }).default(undefined),
}).required();

function Kind() {
    const navigate = useNavigate();
    const params = useParams();
    const {updateStepKind, registrierungsformular} = useContext(AppContext);
    const { register, handleSubmit, watch, reset, formState: { errors, } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            berufstatus: 'angestellter',
            kirchsteuerpflicht: registrierungsformular?.kinder[Number(params.id) - 1]?.kirchsteuerpflicht ?? 'vd',
            kirchsteuerpflichtigGewesen: registrierungsformular?.kinder[Number(params.id) - 1]?.kirchsteuerpflichtigGewesen === 'true' || registrierungsformular?.kinder[Number(params.id) - 1]?.kirchsteuerpflichtigGewesen,
            ...registrierungsformular?.kinder[Number(params.id) - 1],
        },
    });

    useEffect(() => {
        reset({
            vorname: null,
            nachname: null,
            geschlecht: null,
            gradDerBehinderung: 'kein',
            berufstatus: 'angestellter',
            berufsbezeichnung:null,
            geburtsdatum: null,
            idNummer: null,
            kindergeldberechtigung: null,
            leiblichesElternteil: undefined,
            kirchsteuerpflicht: registrierungsformular?.kinder[Number(params.id - 1)]?.kirchsteuerpflicht ?? 'vd',
            kirchsteuerpflichtigGewesen: registrierungsformular?.kinder[Number(params.id - 1)]?.kirchsteuerpflichtigGewesen === 'true' || registrierungsformular?.kinder[Number(params.id) - 1]?.kirchsteuerpflichtigGewesen,
            ...registrierungsformular?.kinder[Number(params.id - 1)],
        });
    }, [params.id]);

    const berufstatus = watch('berufstatus');
    const anderesLeiblichesElternteil = watch('anderesLeiblichesElternteil');
    const kirchsteuerpflicht = watch('kirchsteuerpflicht');
    const kirchsteuerpflichtigGewesen = watch('kirchsteuerpflichtigGewesen');

    const onSubmit = (data) => {
        updateStepKind(params.id - 1, data);

        if (registrierungsformular.kinder.length === Number(params.id)) {
            // wenn es das letzte Kind war.
            navigate('/zusammenfassung');
        } else {
            navigate(`/familienmitglieder/kind/${Number(params.id) + 1}`);
        }
    };

    return (
        <>
            <h1 className="headline--level3 headline--green">{registrierungsformular?.kinder[Number(params.id - 1)]?.vorname ?? '-'} {registrierungsformular?.kinder[Number(params.id) - 1]?.nachname ?? ''}</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Input readOnly errors={errors} label="Vorname" name="vorname" register={register} />
                    <Input readOnly errors={errors} label="Nachname" name="nachname" register={register} />
                </Row>

                <Row>
                    <Select errors={errors} label="Geschlecht" name="geschlecht" register={register} options={{
                        maennlich: 'männlich',
                        weiblich: 'weiblich',
                        diverse: 'diverse',
                    }} />
                    <Input type="date" errors={errors} label="Geburtsdatum" name="geburtsdatum" register={register} />
                </Row>

                <Row>
                    <Select errors={errors} label="Kirchsteuerpflichtig" name="kirchsteuerpflicht" register={register} default="vd" options={{
                        'ak': 'altkatholisch',
                        'ev': 'evangelisch',
                        'fg': 'freireligiöse Gemeinde',
                        'fr': 'französisch-reformiert',
                        'isr': 'israelitisch',
                        'jd': 'jüdisch',
                        'lt': 'evangelisch-lutherisch',
                        'rf': 'evangelisch-reformiert',
                        'rk': 'römisch-katholisch',
                        'un': 'unitarisch-protestantisch',
                        'vd': 'keine Kirchensteuerpflicht',
                    }} />
                    {kirchsteuerpflicht !== 'vd' ?
                        <Row>
                            <Input type="date" errors={errors} label="Kircheneintritt" name="kircheneintritt" register={register} />
                        </Row>
                        : ''}
                </Row>

                {kirchsteuerpflicht === 'vd' ?
                    <Row>
                        <YesNo errors={errors} label="Waren Sie Kirchensteuerpflichtig?" name="kirchsteuerpflichtigGewesen" register={register} />
                        {kirchsteuerpflichtigGewesen === 'true' || kirchsteuerpflichtigGewesen === true ?
                            <Input type="date" errors={errors} label="Kirchenaustritt" name="kirchenaustritt" register={register} />
                            : ''}
                    </Row>
                    : ''}

                <Row>
                    <Input errors={errors} label="Steuer-Id" name="idNummer" register={register} />
                </Row>

                <Row>
                    <Select errors={errors} label="Berufsstatus" name="berufstatus" register={register} default="kein" options={{
                        angestellter: 'Angestellte/r',
                        selbstaendiger: 'Selbständige/r',
                        schueler: 'Student/in Schüler/in Auszubildende/r Praktikant/in',
                        ohne: 'ohne Beschäftigung',

                    }} />
                    {berufstatus === 'angestellter' || berufstatus === 'selbstaendiger' ?
                        <Input errors={errors} label="Berufsbezeichnung" name="berufsbezeichnung" register={register} />
                    : ''}
                </Row>

                <Row>
                    <Select errors={errors} label="Grad der Behinderung" name="gradDerBehinderung" register={register} default="kein" options={{
                        'kein': '-',
                        10: '10',
                        20: '20',
                        30: '30',
                        40: '40',
                        50: '50',
                        60: '60',
                        70: '70',
                        80: '80',
                        90: '90',
                        100: '100',
                    }} />

                    <YesNo errors={errors} label="Kindergeldberechtigung" name="kindergeldberechtigung" register={register} />
                </Row>

                <Row>
                    <YesNo errors={errors} label="Abweichendes leibliches Elternteil?" name="anderesLeiblichesElternteil" register={register} />
                </Row>
                { anderesLeiblichesElternteil === 'true' ?
                    <>
                        <Row>
                            <Input errors={errors} label="Vorname" name="leiblichesElternteil.vorname" register={register} />
                            <Input errors={errors} label="Nachname" name="leiblichesElternteil.nachname" register={register} />
                        </Row>

                        <Row>
                            <Input type="date" errors={errors} label="Geburtsdatum" name="leiblichesElternteil.geburtsdatum" register={register} />
                            <Input errors={errors} label="Steuer-Id" name="leiblichesElternteil.idNummer" register={register} />
                        </Row>

                        <Row>
                            <Select errors={errors} label="Grad der Behinderung" name="leiblichesElternteil.gradDerBehinderung" register={register} default="kein" options={{
                                'kein': '-',
                                10: '10',
                                20: '20',
                                30: '30',
                                40: '40',
                                50: '50',
                                60: '60',
                                70: '70',
                                80: '80',
                                90: '90',
                                100: '100',
                            }} />
                        </Row>

                        <Row>
                            <Input errors={errors} label="Straße" name="leiblichesElternteil.adresse.strasse" register={register} />
                            <Input errors={errors} label="Hausnummer" name="leiblichesElternteil.adresse.hausnummer" register={register} />
                        </Row>

                        <Row>
                            <Input errors={errors} label="Plz" name="leiblichesElternteil.adresse.plz" register={register} />
                            <Input errors={errors} label="Ort" name="leiblichesElternteil.adresse.ort" register={register} />
                        </Row>
                    </>
                : '' }

                <FormController handlePrevStep={() => {
                    if (Number(params.id) === 1) {
                        if (registrierungsformular.ehepartner) {
                            navigate('/familienmitglieder/ehepartner');
                        } else {
                            navigate('/familienmitglieder');
                        }
                    }

                    if (Number(params.id) > 1) {
                        navigate(`/familienmitglieder/kind/${Number(params.id) - 1}`);
                    }
                }} />
            </form>
        </>
    );
}

export default Kind;
