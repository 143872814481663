import React, {useContext} from 'react';
import Input from '../../components/Input.jsx';
import {useForm} from 'react-hook-form';
import Select from '../../components/Select.jsx';
import Row from '../../components/Row.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import FormController from '../../components/Registrierungsformular/FormController.jsx';
import {AppContext} from '../../context/AppContext.jsx';
import {useNavigate} from 'react-router-dom';
import YesNo from '../../components/YesNo.jsx';

const schema = yup.object({
    vorname: yup.string().required('Bitte geben Sie ihren Vornamen an.'),
    nachname: yup.string().required('Bitte geben Sie ihren Nachnamen an.'),
    geschlecht: yup.string().required('Bitte geben Sie ihr Geschlecht an.'),
    geburtsdatum: yup.date('Bitte geben Sie das Geburtsdatum an.').typeError('Bitte geben Sie ihr Geburtsdatum an.').required(),
    familienstand: yup.string().required('Bitte geben Sie ihren Familienstand an.'),
    familienstandDatum: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).when("familienstand", {
        is: (value) => {
            if (value === 'verheiratet' || value === 'geschieden' || value === 'verwitwet' || value === 'getrenntlebend') {
                return true;
            } else {
                return false;
            }
        },
        then: yup.date().required('Bitte geben Sie ein Datum an.').nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
    kirchsteuerpflicht: yup.string().required('Bitte geben Sie die Kirchsteuerpflicht an.'),
    kirchsteuerpflichtigGewesen: yup.string('Bitte geben Sie an, ob Sie Kirchensteuerpflichtig gewesen sind.').nullable().transform((curr, orig) => orig === '' ? null : curr).when("kirchsteuerpflicht", {
        is: (value) => value === 'vd',
        then: yup.string().required('Bitte geben Sie an, ob Sie Kirchensteuerpflichtig gewesen sind.').nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
    kircheneintritt: yup.date('Bitte geben Sie an, ob Sie das Datum des Kircheneintritts an.').nullable().transform((curr, orig) => orig === '' ? null : curr).when("kirchsteuerpflicht", {
        is: (value) => value !== 'vd',
        then: yup.date().required('Bitte geben Sie an, ob Sie das Datum des Kircheneintritts an.').nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
    kirchenaustritt: yup.date('Bitte geben Sie an, ob Sie das Datum des Kirchenaustritts an.').nullable().transform((curr, orig) => orig === '' ? null : curr).when("kirchsteuerpflichtigGewesen", {
        is: (value) => value === 'true' || value === true,
        then: yup.date().required('Bitte geben Sie an, ob Sie das Datum des Kirchenaustritts an.').nullable().transform((curr, orig) => orig === '' ? null : curr)
    }),
    idNummer: yup.string().required('Bitte geben Sie ihre Steuer-Id an.'),
    berufstatus: yup.string().required('Bitte geben Sie ihren Berufsstatus an.'),
    berufsbezeichnung: yup.string().when("berufstatus", {
        is: (value) => value === 'angestellter' || value === 'selbstaendiger',
        then: yup.string().required('Bitte geben Sie ihre Berufsbezeichnung an.')
    }),
    gradDerBehinderung: yup.string().required('Bitte geben Sie an, ob ein Grad der Behinder vorliegt.'),
    email: yup.string().required('Bitte geben Sie ihre E-Mail an.'),
    adresse: yup.object({
        strasse: yup.string().required('Bitte geben Sie ihre Straße an.'),
        hausnummer: yup.string().required('Bitte geben Sie ihre Hausnummer an.'),
        plz: yup.string().required('Bitte geben Sie ihre PLZ an.'),
        ort: yup.string().required('Bitte geben Sie ihren Ort an.'),
    }),
    telefon: yup.string().required(),
    allgemein: yup.object({
        steuernummer: yup.string().required('Bitte geben Sie ihre Steuernummer an.'),
        finanzamt: yup.string().required('Bitte geben Sie ihre Steuernummer an.'),
    }),
}).required();

function Allgemein(props) {
    const navigate = useNavigate();
    const {updateStepAllgemein, registrierungsformular} = useContext(AppContext);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            berufstatus: 'angestellter',
            kirchsteuerpflicht: registrierungsformular?.steuerpflichtiger?.kirchsteuerpflicht ?? 'vd',
            kirchsteuerpflichtigGewesen: registrierungsformular?.steuerpflichtiger?.kirchsteuerpflichtigGewesen === 'true' || registrierungsformular?.steuerpflichtiger?.kirchsteuerpflichtigGewesen,
            ...registrierungsformular?.steuerpflichtiger
        },
    });

    const berufstatus = watch('berufstatus');
    const kirchsteuerpflicht = watch('kirchsteuerpflicht');
    const kirchsteuerpflichtigGewesen = watch('kirchsteuerpflichtigGewesen');
    const familienstand = watch('familienstand');

    const onSubmit = (data) => {
        updateStepAllgemein(data);
        navigate('/bemessungsgrundlage');
    };

    console.log(errors);

    return (
        <>
            <h1 className="headline--level3 headline--green">Steuerpflichtiger</h1>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Input errors={errors} label="Vorname" name="vorname" register={register} />
                    <Input errors={errors} label="Nachname" name="nachname" register={register} />
                </Row>

                <Row>
                    <Select errors={errors} label="Geschlecht" name="geschlecht" register={register} options={{
                        maennlich: 'männlich',
                        weiblich: 'weiblich',
                        diverse: 'diverse',
                    }} />

                    <Input type="date" errors={errors} label="Geburtsdatum" name="geburtsdatum" register={register} />
                </Row>

                <Row>
                    <Select errors={errors} label="Familienstand" name="familienstand" register={register} options={{
                        ledig: 'ledig',
                        verheiratet: 'verheiratet',
                        verwitwet: 'verwitwet',
                        geschieden: 'geschieden',
                        'eingetragene Lebenspartnerschaft': 'eingetragene Lebenspartnerschaft',
                        'eingetragene Lebenspartnerin/eingetragener Lebenspartner verstorben': 'eingetragene Lebenspartnerin/eingetragener Lebenspartner verstorben',
                        'eingetragene Lebenspartnerschaft aufgehoben': 'eingetragene Lebenspartnerschaft aufgehoben',
                    }} />

                    {familienstand === 'verheiratet' || familienstand === 'geschieden' || familienstand === 'verwitwet' || familienstand === 'getrenntlebend' ?
                        <Input type="date" errors={errors} label={`${familienstand} seit`} name="familienstandDatum" register={register} />
                    : ''}
                </Row>

                <Row>
                    <Select errors={errors} label="Kirchsteuerpflichtig" name="kirchsteuerpflicht" register={register} default="vd" options={{
                        'ak': 'altkatholisch',
                        'ev': 'evangelisch',
                        'fg': 'freireligiöse Gemeinde',
                        'fr': 'französisch-reformiert',
                        'isr': 'israelitisch',
                        'jd': 'jüdisch',
                        'lt': 'evangelisch-lutherisch',
                        'rf': 'evangelisch-reformiert',
                        'rk': 'römisch-katholisch',
                        'un': 'unitarisch-protestantisch',
                        'vd': 'keine Kirchensteuerpflicht',
                    }} />
                    {kirchsteuerpflicht !== 'vd' ?
                        <Row>
                            <Input type="date" errors={errors} label="Kircheneintritt" name="kircheneintritt" register={register} />
                        </Row>
                        : ''}
                </Row>

                {kirchsteuerpflicht === 'vd' ?
                    <Row>
                        <YesNo errors={errors} label="Waren Sie Kirchensteuerpflichtig?" name="kirchsteuerpflichtigGewesen" register={register} />
                        {kirchsteuerpflichtigGewesen === 'true' || kirchsteuerpflichtigGewesen === true ?
                            <Input type="date" errors={errors} label="Kirchenaustritt" name="kirchenaustritt" register={register} />
                        : ''}
                    </Row>
                : ''}

                <Row>
                    <Input errors={errors} label="Steuer-Id" name="idNummer" register={register} />
                </Row>

                <Row>
                    <Select errors={errors} label="Berufsstatus" name="berufstatus" register={register} default="kein" options={{
                        angestellter: 'Angestellte/r',
                        selbstaendiger: 'Selbständige/r',
                        schueler: 'Student/in Schüler/in Auszubildende/r Praktikant/in',
                        ohne: 'ohne Beschäftigung',

                    }} />
                    {berufstatus === 'angestellter' || berufstatus === 'selbstaendiger' ?
                        <Input errors={errors} label="Berufsbezeichnung" name="berufsbezeichnung" register={register} />
                    : ''}
                </Row>

                <Row>
                    <Select errors={errors} label="Grad der Behinderung" name="gradDerBehinderung" register={register} default="kein" options={{
                        'kein': '-',
                        10: '10',
                        20: '20',
                        30: '30',
                        40: '40',
                        50: '50',
                        60: '60',
                        70: '70',
                        80: '80',
                        90: '90',
                        100: '100',
                    }} />
                </Row>

                <Row>
                    <Input errors={errors} label="Straße" name="adresse.strasse" register={register} />
                    <Input errors={errors} label="Hausnummer" name="adresse.hausnummer" register={register} />
                </Row>

                <Row>
                    <Input errors={errors} label="Plz" name="adresse.plz" register={register} />
                    <Input errors={errors} label="Ort" name="adresse.ort" register={register} />
                </Row>

                <Row>
                    <Input errors={errors} label="Telefonnummer" name="telefon" register={register} />
                    <Input type="email" errors={errors} label="E-Mail" name="email" register={register} />
                </Row>

                <div className="headline headline--level4 headline--green">Allgemein</div>

                <Row>
                    <Input errors={errors} label="Finanzamt" name="allgemein.finanzamt" register={register} />
                    <Input errors={errors} label="Steuernummer" name="allgemein.steuernummer" register={register} />
                </Row>

                <FormController handlePrev={() => {
                    navigate('/');
                }} />
            </form>
        </>
    );
}

export default Allgemein;
