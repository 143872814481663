import React from 'react';
import {Link} from 'react-router-dom';

function Sidebar(props) {
    return (
        <div className="registrierungsformular-sidebar">
            <div className="registrierungsformular-sidebar__item-wrapper">
                {props.links.map((link, index) => {
                    let className = 'registrierungsformular-sidebar__item';

                    if (link.active) {
                        className = className + ' active';
                    }

                    return (
                        <div key={index} className={className} disabled={link.disabled ?? false}>
                            <div className="registrierungsformular-sidebar__number">{index + 1}.</div>
                            {link.name}
                        </div>
                    );
                })}
            </div>

            <div className="registrierungsformular-sidebar__info">
                <img className="registrierungsformular-sidebar__info-icon" src="/images/lhv/icons/lock.png" alt="" />
                Wir nutzen Deine persönlichen Daten nur temporär zur Erstellung des Mitglied-Antrag.
            </div>
        </div>
    );
}

export default Sidebar;
