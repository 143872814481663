import React from 'react';
import Button from '../../components/Button.jsx';

function Fehler() {
  return (
    <div>
        <div className="headline headline--level3 headline--green">Fehler</div>

        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
            <p className="registrierungsformular-text">
                Es ist leider ein Fehler aufgetreten. Sie können die Beitrittserklärung hier herunterladen oder es zu
                einen späteren Zeitpunkt nochmal versuchen.
            </p>

            <Button href="/mitglied-werden">Okay</Button>
        </div>
    </div>
  );
}

export default Fehler;
